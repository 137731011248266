import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql, Link } from 'gatsby'

import Logo from '../images/logo.svg'
import BackgroundSvg from '../svg/footer-bg.svg'
import TwitterIcon from '../images/twitter.svg'
import LinkedinIcon from '../images/linkedin.svg'

const Footer = ({ data }) => (
  <>
    <PageFooter>
      <Inner className="container">
        <FooterLeft>
          <Logo />
          <Phone>Tel: +44-203-637-9467</Phone>
          <Email to="/engagement#form">Contact Us</Email>
          <Socials>
            <a href="https://twitter.com/metadatasystems" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/findmetadatasystems/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinIcon />
            </a>
          </Socials>
        </FooterLeft>
        <FooterRight>
          <Slogan>Better Data.</Slogan>
          <Nav>
            <ul>
              {data.wordpressWpApiMenusMenusItems.items.map((item, i) => (
                <li key={i}>
                  <Link
                    to={`/${item.object_slug}`}
                    activeClassName="active"
                    exit={{ length: 1 }}
                    entry={{ length: 1 }}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                </li>
              ))}
            </ul>
          </Nav>
          <p>UK Registrations: 1105-2780 | 287-2158-77</p>
          <p>© {new Date().getFullYear()} Meta Data Systems Limited. All Rights Reserved.</p>
        </FooterRight>
      </Inner>
      <Gradient />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(){ window.ldfdr = window.ldfdr || {}; (function(d, s, ss, fs){ fs = d.getElementsByTagName(s)[0]; function ce(src){ var cs = d.createElement(s); cs.src = src; setTimeout(function(){fs.parentNode.insertBefore(cs,fs)}, 1); } ce(ss); })(document, 'script', 'https://sc.lfeeder.com/lftracker_v1_bElvO73eNR1aZMqj.js'); })();
        `
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
        !function(s,n,i,t,c,h){s.SnitchObject=i;s[i]||(s[i]=function(){
          (s[i].q=s[i].q||[]).push(arguments)});s[i].l=+new Date;c=n.createElement(t);
          h=n.getElementsByTagName(t)[0];c.src='//snid.snitcher.com/8414013.js';
          h.parentNode.insertBefore(c,h)}(window,document,'snid','script');
          snid('verify', '8414013');
        `
        }}
      />
    </PageFooter>
  </>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpApiMenusMenusItems(wordpress_id: { eq: 3 }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)

const PageFooter = styled.footer`
  position: relative;
  background-color: #151515;
  background-image: url(${BackgroundSvg});
  color: #fff;
  padding: 12rem 0 10rem;
  z-index: 10;

  @media (max-width: 650px) {
    padding: 6rem 0;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #8b01b4 0%, #00ffe2 100%);
  opacity: 0.1;
  z-index: 2;
`

const Inner = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 5;

  @media (max-width: 650px) {
    text-align: center;
  }
`

const FooterLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  @media (max-width: 650px) {
    align-items: center;
    width: 100%;
  }

  svg {
    width: 13.5rem;
    height: 4.85rem;
  }
`

const FooterRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  text-align: right;

  @media (max-width: 650px) {
    width: 100%;
    text-align: center;
  }

  p {
    font-family: ${props => props.theme.fonts.roboto};
    font-size: 1.5rem;
    margin-bottom: 1rem;

    @media (max-width: 650px) {
      font-size: 1.8rem;
    }
  }
`

const Phone = styled.p`
  font-size: 2.2rem;
  font-weight: 700;
  margin: 3rem 0 2rem;
`

const Email = styled(Link)`
  font-size: 1.8rem;
  font-weight: 300;
  color: ${props => props.theme.colours.primary};
  text-decoration: none;
  transition: opacity 0.3s ease;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 650px) {
    font-size: 2rem;
  }

  &:hover {
    opacity: 0.6;
  }
`

const Socials = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  a {
    margin-right: 15px;
  }

  svg {
    width: 20px;

    &:hover {
      path,
      circle {
        fill: #00ffe2 !important;
      }
    }

    path,
    circle {
      fill: #ffffff !important;
      transition: fill 0.3s ease;
    }
  }
`

const Slogan = styled.p`
  && {
    font-size: 3rem;
    font-weight: 700;

    @media (max-width: 650px) {
      margin: 2rem 0 1rem;
    }
  }
`

const Nav = styled.nav`
  margin: 0 0 1.2rem;
  transform: translateY(1rem);

  @media (max-width: 650px) {
    margin: 0 0 2rem;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    list-style-type: none;
    margin: 0;
    align-items: center;

    @media (max-width: 650px) {
      justify-content: center;
    }
  }

  li {
    margin-left: 5rem;

    &:first-child {
      margin-left: 0;
    }
  }

  a {
    font-family: ${props => props.theme.fonts.roboto};
    font-size: 1.4rem;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    border-bottom: 0.2rem solid transparent;
    transition: color 0.3s ease;
    padding-bottom: 0.3rem;

    @media (max-width: 650px) {
      font-size: 1.8rem;
    }

    &:hover {
      color: ${props => props.theme.colours.primary};
    }
  }
`
