import { createGlobalStyle } from 'styled-components'
import { resetStyles } from '../styles/reset'

const GlobalStyle = createGlobalStyle`
  ${resetStyles()}
  html {
    font-size: 10px;
  }
  @media (min-width: 1700px) {
    html {
      font-size: 13px;
    }
  }
  @media (max-width: 1000px) {
    html {
      font-size: 8px;
    }
  }
  @media (max-width: 650px) {
    html {
      font-size: 7px;
    }
  }
  body {
    font-family: ${props => props.theme.fonts.san};
    font-weight: 300;
    line-height: 1.2;
    background-color: #040808;
  }
  h1, h2, h3, li {
    font-family: ${props => props.theme.fonts.san};
    color: #fff;
  }
  a {
    color: ${props => props.theme.colours.primary};
  }
  p {
    font-family: ${props => props.theme.fonts.roboto};
    font-size: 1.6rem;
    line-height: 1.6;
    color: #fff;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 1000px) {
    p {
      font-size: 2rem;
    }
  }
  .button {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 1.2;
    font-weight: 300;
    letter-spacing: 0.1rem;
    text-decoration: none;
    padding: 1.5rem 3.5rem;
    color: ${props => props.theme.colours.black};
    background-color: ${props => props.theme.colours.primary};
    border: 0;
    border-radius: 50px;
    transition: opacity 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    border: 0;

    &:hover {
      transform: scale(1.05)
    }

    &:focus {
      outline: 0;
    }
  }
  @media (max-width: 650px) {
    .button {
      font-size: 2rem;
      padding: 2rem 3.5rem;
    }
  }
  .container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 119rem;
    padding: 0 3rem;
  }
  @media (max-width: 650px) {
    .container {
      padding: 0 4rem;
    }
  }
  .main {
    overflow: hidden;
  }
`

export default GlobalStyle
