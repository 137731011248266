const theme = {
  colours: {
    primary: '#00FFE2',
    secondary: '#7E73B2',
    black: '#0D0D0D'
  },
  fonts: {
    san: `'San Fransico', sans-serif`,
    roboto: `'Roboto', sans-serif`
  }
}

export default theme
