import { StaticQuery, graphql, Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { staggeredFadeIn } from './animation/animations'

const HeaderNavigation = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        wordpressWpApiMenusMenusItems(wordpress_id: { eq: 4 }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => (
      <Nav initial="hidden" animate="visible" variants={staggeredFadeIn.parent}>
        <ul>
          {data.wordpressWpApiMenusMenusItems.items.map((item, i) => (
            <motion.li key={i} variants={staggeredFadeIn.child}>
              <Link
                activeClassName="active"
                to={`/${item.object_slug}`}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
            </motion.li>
          ))}
        </ul>
      </Nav>
    )}
  />
)

export default HeaderNavigation

const Nav = styled(motion.nav)`
  margin-left: auto;

  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
  }

  li {
    margin: 0 1.5rem;

    &:last-child {
      margin-right: 0;
    }

    &:nth-child(1),
    &:nth-child(2) {
      a {
        color: #e6c6ff;

        &:hover {
          color: ${props => props.theme.colours.primary};
        }
      }
    }
  }

  a {
    font-size: 1.5rem;
    text-decoration: none;
    color: #fff;
    transition: color 0.3s ease;

    @media (max-width: 650px) {
      font-size: 15px;
    }

    &:hover {
      color: ${props => props.theme.colours.primary};
    }
  }
`
