import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import theme from './theme'
import { pageTransition } from './animation/animations'

import GlobalStyle from './global-styles'

import '../fonts/sf.css'

import Header from './header'
import Footer from './footer'
import CookieBanner from './cookie-banner'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const Layout = ({ children, location }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <Header siteTitle="Volume Network" />
      <AnimatePresence>
        <motion.main
          key={location.pathname}
          variants={pageTransition}
          initial="initial"
          animate="enter"
          exit="exit"
          id="main"
        >
          <main className="main">{children}</main>
        </motion.main>
      </AnimatePresence>
      <CookieBanner />
      <Footer />
    </>
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
