import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { fadeInDelay, staggeredFadeInUp } from './animation/animations'

const MobileNavigation = ({ data, handleOpenNav, mobileNavActive }) => (
  <AnimatePresence>
    {mobileNavActive && (
      <Outer
        initial="hidden"
        animate={mobileNavActive ? 'visible' : 'hidden'}
        exit="exit"
        variants={fadeInDelay}
      >
        <Nav initial="hidden" animate="visible" variants={staggeredFadeInUp.parent}>
          <ul>
            <motion.li variants={staggeredFadeInUp.child}>
              <Link
                activeClassName="active"
                to="/"
                onClick={handleOpenNav}
                dangerouslySetInnerHTML={{ __html: 'Home' }}
              />
            </motion.li>
            {data.wordpressWpApiMenusMenusItems.items.map((item, i) => (
              <motion.li key={i} variants={staggeredFadeInUp.child}>
                <Link
                  activeClassName="active"
                  to={`/${item.object_slug}`}
                  onClick={handleOpenNav}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
              </motion.li>
            ))}
          </ul>
        </Nav>
      </Outer>
    )}
  </AnimatePresence>
)

export default props => (
  <StaticQuery
    query={graphql`
      query MobileHeadingQuery {
        wordpressWpApiMenusMenusItems(wordpress_id: { eq: 2 }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => <MobileNavigation data={data} {...props} />}
  />
)

const Outer = styled(motion.aside)`
  position: fixed;
  top: 0%;
  left: 0;
  width: 100vw;
  height: 100vh;
  will-change: transform;
  background: ${props => props.theme.colours.black};
  z-index: 60;
`

const Nav = styled(motion.nav)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    text-align: center;
    margin: 0;
  }

  li {
    margin: 0 1.8rem;
  }

  a {
    font-size: 3vw;
    line-height: 1.5;
    text-decoration: none;
    color: #fff;
    padding-bottom: 0.8rem;
    transition: color 1s ease;

    @media (max-width: 1250px) {
      font-size: 5vw;
    }

    @media (max-width: 650px) {
      font-size: 8vw;
    }

    &.active,
    &:hover {
      color: ${props => props.theme.colours.primary} !important;
    }
  }
`
