module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"meta","short_name":"meta","start_url":"/","background_color":"#fff","theme_color":"#00FFE2","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"01c14f1e228b2772b6ecb8e366419d66"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-119811717-1","head":false,"pageTransitionDelay":400},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N9K48HL"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
