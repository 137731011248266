import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import { headerFadeIn } from './animation/animations'

import MobileNav from './mobile-navigation'
import HeaderNavigation from './header-navigation'
import Logo from '../images/logo.svg'

const Header = () => {
  const [mobileNavActive, setMobileNavActive] = useState(undefined)
  const [animate, setAnimated] = useState(false)
  const [animationDelay, setAnimationDelay] = useState(0)

  const handleOpenNav = () => {
    setMobileNavActive(!mobileNavActive)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        document.body.classList.contains('home') &&
        sessionStorage.getItem('preloader') !== 'played'
      ) {
        setAnimationDelay(3.5)
      }
    }

    setAnimated(true)
  }, [])

  return (
    <>
      <MobileNav handleOpenNav={handleOpenNav} mobileNavActive={mobileNavActive} />
      <Outer>
        <Inner
          className="container"
          initial="hidden"
          animate={animate ? 'visible' : 'hidden'}
          variants={headerFadeIn}
          custom={animationDelay}
        >
          <HeadingLink to="/">
            <Logo />
          </HeadingLink>
          <HeaderNavigation />
          <MobileNavIcon onClick={() => handleOpenNav()} className={mobileNavActive ? 'open' : ''}>
            <span></span>
            <span></span>
            <span></span>
          </MobileNavIcon>
        </Inner>
      </Outer>
    </>
  )
}

export default Header

const Outer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`

const Inner = styled(motion.div)`
  display: flex;
  align-items: center;

  @media (max-width: 650px) {
    background-color: ${props => props.theme.colours.black};
  }

  && {
    padding: 3rem;

    @media (max-width: 650px) {
      padding: 2rem 3rem;
    }
  }
`

const HeadingLink = styled(Link)`
  width: 9.3rem;
  height: 3.3rem;
  color: #fff;
  text-decoration: none;

  svg {
    width: 100%;
    height: 100%;
  }
`

const MobileNavIcon = styled.div`
  position: relative;
  top: 0.2rem;
  width: 4.5rem;
  height: 3.375rem;
  transition: 0.5s ease-in-out;
  margin-left: 3rem;
  cursor: pointer;
  transform: scale(0.75);
  will-change: transform;
  z-index: 10;

  @media (max-width: 1000px) {
    transform: scale(1);
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #fff;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    will-change: transform, opacity;
  }

  span {
    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 1.15rem;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 2.3rem;
      transform-origin: left center;
    }
  }

  &.open span {
    &:nth-child(1) {
      transform: rotate(45deg);
      top: -0.225rem;
      left: 0.6rem;
    }
    &:nth-child(2) {
      width: 0;
      opacity: 0;
    }
    &:nth-child(3) {
      transform: rotate(-45deg);
      top: 3rem;
      left: 0.6rem;
    }
  }
`
