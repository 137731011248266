import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'

const CookieBanner = () => {
  const [show, setShow] = useState(false)
  const [animate, setAnimate] = useState(false)

  const handleButtonAccept = () => {
    localStorage.setItem('cookies', 'accepted')
    setAnimate(true)
    setTimeout(() => {
      setShow(false)
    }, 500)
  }

  const handleButtonReject = () => {
    localStorage.setItem('cookies', 'rejected')
    setAnimate(true)
    setTimeout(() => {
      setShow(false)
    }, 500)
  }

  useEffect(() => {
    if (!localStorage.getItem('cookies')) {
      setShow(true)
    }
  }, [])

  return (
    <>
      {show && (
        <CookieOuter className={animate ? 'hidden' : ''}>
          <Title>Cookies</Title>
          <p>
            We use cookies for a number of reasons, such as to analyse how our site is used. More
            information on how we use cookies can be found <Link to="/cookies">here</Link>.
          </p>
          <Buttons>
            <Button id="cookie-accept" type="button" onClick={handleButtonAccept}>
              accept
            </Button>
            <Button id="cookie-reject" type="button" onClick={handleButtonReject}>
              reject
            </Button>
          </Buttons>
        </CookieOuter>
      )}
    </>
  )
}

export default CookieBanner

const slideInFromTop = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

export const CookieOuter = styled.section`
  position: fixed;
  max-width: 350px;
  bottom: 3rem;
  right: 3rem;
  background: ${props => props.theme.colours.primary};
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transform: translateY(0);
  animation: ${slideInFromTop} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 2000;

  @media (max-width: 600px) {
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
  }

  &.hidden {
    transform: translateY(150%);
  }

  p {
    font-family: ${props => props.theme.fonts.roboto};
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.4;
    color: #000;
  }

  a {
    color: #000;

    &:hover {
      text-decoration: none;
    }
  }
`

export const Title = styled.p`
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 2.5rem !important;
`

export const Buttons = styled.div`
  margin-top: 2rem;
`

export const Button = styled.button`
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 300;
  color: ${props => props.theme.colours.primary};
  letter-spacing: 0.1rem;
  border: 0;
  border-radius: 50px;
  padding: 1rem 2rem;
  margin-right: 1.5rem;
  transition: background 0.3s ease;
  background: #000;
  cursor: pointer;

  &:hover {
    background: #333;
  }
`
