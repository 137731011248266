export const slideInLeft = {
  visible: {
    x: 0,
    transition: {
      type: 'spring',
      damping: 30,
      mass: 1,
      stiffness: 250
    }
  },
  hidden: {
    x: '100%'
  },
  exit: {
    x: '100%'
  }
}

export const fadeIn = {
  visible: i => ({
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: 'linear',
      delay: i
    }
  }),
  hidden: {
    opacity: 0
  },
  exit: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.6,
      ease: 'linear'
    }
  }
}

export const fadeInDelay = {
  visible: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: 'linear'
    }
  },
  hidden: {
    opacity: 0
  },
  exit: {
    opacity: 0,
    transition: {
      type: 'tween',
      duration: 0.6,
      delay: 0.5,
      ease: 'linear'
    }
  }
}

export const drawLine = {
  visible: {
    width: '10rem',
    transition: {
      type: 'tween',
      duration: 0.2,
      ease: 'linear'
    }
  },
  hidden: {
    width: 0
  },
  exit: {
    width: 0
  }
}

export const fadeInUp = {
  visible: i => ({
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      damping: 30,
      mass: 1,
      stiffness: 100,
      delay: i
    }
  }),
  hidden: {
    opacity: 0,
    y: 50
  },
  exit: {
    opacity: 0,
    y: -50,
    transition: {
      type: 'spring',
      damping: 30,
      mass: 1,
      stiffness: 100
    }
  }
}

export const fadeInUpDelayed = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      damping: 30,
      mass: 1,
      stiffness: 100,
      delay: 0.2
    }
  },
  hidden: {
    opacity: 0,
    y: 50
  }
}

export const staggeredFadeInUp = {
  parent: {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    },
    hidden: {
      transition: {
        when: 'afterChildren'
      }
    }
  },
  child: {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 30,
        mass: 1,
        stiffness: 100
      }
    },
    hidden: {
      opacity: 0,
      y: 50
    }
  }
}

export const staggeredFadeInUpLong = {
  parent: {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3
      }
    },
    hidden: {
      transition: {
        when: 'afterChildren'
      }
    }
  },
  child: {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        damping: 30,
        mass: 1,
        stiffness: 100
      }
    },
    hidden: {
      opacity: 0,
      y: 50
    }
  }
}

export const fadeInSlow = {
  visible: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.6,
      ease: 'linear'
    }
  },
  hidden: {
    opacity: 0
  },
  exit: {
    opacity: 0
  }
}

export const fadeInSlowDelayed = {
  visible: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.6,
      ease: 'linear',
      delay: 0.3
    }
  },
  hidden: {
    opacity: 0
  },
  exit: {
    opacity: 0
  }
}

export const pricingTable = {
  parent: {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.3,
        delayChildren: 0.3
      }
    },
    hidden: {
      transition: {
        when: 'afterChildren'
      }
    }
  },
  child: {
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 200,
        damping: 10
      }
    },
    hidden: {
      opacity: 0,
      y: 50
    }
  }
}

export const imageMask = {
  visible: {
    x: 0
  },
  hidden: {
    x: '-100%',
    transition: {
      type: 'tween',
      duration: '1',
      ease: [1, 0, 0, 1]
    }
  },
  exit: {
    x: '-100%'
  }
}

export const headerFadeIn = {
  visible: i => ({
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.4,
      ease: 'linear',
      delay: i
    }
  }),
  hidden: {
    opacity: 0
  }
}

export const pageTransition = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: 0.35,
      delay: 0.35,
      when: 'beforeChildren'
    }
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.35 }
  }
}

export const staggeredFadeIn = {
  parent: {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
        delayChildren: 0.3
      }
    },
    hidden: {
      transition: {
        when: 'afterChildren'
      }
    }
  },
  child: {
    visible: {
      opacity: 1,
      transition: {
        type: 'spring',
        damping: 30,
        mass: 1,
        stiffness: 100
      }
    },
    hidden: {
      opacity: 0
    }
  }
}
